import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRunOutlined";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivismOutlined";
import { useSpring, animated } from "react-spring";
import Counter from "./Counter";
import * as Images from "../../images"
export type AboutChallengeProps = {
  runner?: T.CurrentRunnerFragment | null;
};

interface AnimatedWordProps {
  text: string; // The word to display
  delay: number; // Delay before the animation starts
}
const AnimatedWord:React.FC<AnimatedWordProps> = ({ text, delay }) => {
  // Animation for each word
  const styles = useSpring({
    opacity: 1,
    transform: "translateX(0)",
    from: { opacity: 0, transform: "translateY(20px)" },
    delay,
  });

  return (
    <animated.div style={styles}>
      <UI.Typography
        component="span"
        variant="h1"
      color= "#000000"
        sx={{ mt: 7, fontStyle: "normal", mb: 2 }}
      >
        {text}
      </UI.Typography>
    </animated.div>
  );
};
const AboutChallenge: React.FC<AboutChallengeProps> = ({ runner }) => {


  return (
    <UI.Box sx={{}}>
      <UI.Box
        sx={{
          textAlign: "center",
          padding: "20px 0",
          display: "flex",
          flexDirection: { xs: "column-reverse" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <UI.Box
          component="img"
          src={Images.Header}
          sx={{ width: { xs: "100%", md: "25%" }, height: "auto" }}
        />
        <UI.Box sx={{ ml: { xs: 0, width: { xs: "100%", md: "50%" } } }}>
          <UI.Typography
            variant="h1"
            color="primary"
            sx={{
              my: 2,

              fontSize: { xs: "60px", md: "75px" },
              fontStyle: "normal",
            }}
          >
            January Challenge
          </UI.Typography>
          <UI.Typography
            variant="h1"
            color="#000000"
            sx={{ my: 2, fontStyle: "normal" }}
          >
            2025{" "}
          </UI.Typography>

          <UI.Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
              gap: "16px",
            }}
          >
            <AnimatedWord text="Welcome " delay={400} />{" "}
            <AnimatedWord
              text={runner ? runner?.name.split(" ")[0] + "!" : "GoodGymmer"}
              delay={800}
            />
          </UI.Box>
        </UI.Box>
      </UI.Box>
      <UI.Container sx={{ mt: 2 }}>
        <UI.Typography variant="h4" sx={{ fontWeight: "normal" }}>
          The <span style={{ color: "#E11018" }}>January Challenge</span> is
          back. From the 1st to the 31st of January, across the country, we are
          going to walk, run and cycle to complete as many good deeds as we can.
        </UI.Typography>
      </UI.Container>
      <UI.Container>
        <UI.Typography
          variant="h1"
          sx={{
            mt: 7,
            fontStyle: "normal",
            mb: 2,
            textTransform: "uppercase",
            textAlign: "center",
            fontSize:"58px"
          }}
        >
          GoodGym Goal
        </UI.Typography>
        <Counter number={5000} text="Good deeds" />
        <Counter number={65000} text="Km Travelled" />
      </UI.Container>
      <UI.Container sx={{ my: 3, display: "flex" }}>
        <DirectionsRunIcon sx={{ mr: 6, fontSize: "44px" }} />
        <UI.Typography variant="h5" sx={{ my: 1 }}>
          Get your January fitness fix - run, walk or cycle to sessions to
          contribute to our distance target
        </UI.Typography>{" "}
      </UI.Container>
      <UI.Container sx={{ my: 3, display: "flex" }}>
        <VolunteerActivismIcon sx={{ mr: 6, fontSize: "44px" }} />
        <UI.Typography variant="h5" sx={{ my: 1 }}>
          All of your good deeds count towards our national target of 5000 with
          our biggest aim to make as much positive impact as possible during
          January 2025.
        </UI.Typography>{" "}
      </UI.Container>
    </UI.Box>
  );
};

export default AboutChallenge;

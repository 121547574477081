import React, { useState, useEffect } from "react";
import * as UI from "@goodgym/components";

export type CountProps = {
  number: number;
  text: string;
}

const Counter:React.FC<CountProps> = ({number, text}) => {
  const [count, setCount] = useState(number - 20);
    const [isVisible, setIsVisible] = React.useState(false);
    const containerRef = React.useRef(null);

  const target = number; 
  const intervalDuration = 100; 

    useEffect(() => {
      if (isVisible)
        if (count < target) {
          const interval = setInterval(() => {
            setCount((prevCount) => {
              const nextCount = prevCount + 1;
              if (nextCount >= target) {
                clearInterval(interval);
                return target;
              }
              return nextCount;
            });
          }, intervalDuration);

          return () => clearInterval(interval);
        }
    }, [count, target, isVisible]);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);



  return (
    <UI.Typography ref={containerRef} variant="h1" sx={{ textAlign: "center" }}>
      <span style={{ color: "red" }}>{count}</span> {text}
    </UI.Typography>
  );
};

export default Counter;

import * as UI from "@goodgym/components";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRunOutlined";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivismOutlined";


export type MidBannerProps = {
  numberOfDeeds?: number;
  numberOfKm?: number;
  subtitleDeeds?: string;
  subtitleKm?: string;
};
const MidBanner: React.FC<MidBannerProps> = ({
  numberOfDeeds,
  numberOfKm,
  subtitleDeeds,
  subtitleKm
}) => {
  return (
    <>
      <UI.Box
        sx={{
          bgcolor: "black.main",
          minHeight: "300px",
          textAlign: "center",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          gap: "50px",
        }}
      >
        <UI.Grid container sx={{ marginBottom: "30px", mt: { xs: 1, md: 5 } }}>
          <UI.Grid item xs={12} md={6} sx={{ mt: { xs: 3, md: 0 } }}>
            <VolunteerActivismIcon
              sx={{
                fontSize: "74px",
                color: "#ffffff",
                textAlign: "center",
              }}
            />
            <UI.Typography
              variant="h1"
              color="neutral.light"
              sx={{ textTransform: "uppercase", fontColor: "white" }}
            >
              {numberOfDeeds?.toLocaleString()}
            </UI.Typography>
            <UI.Typography
              variant="h1"
              color="neutral.light"
              sx={{ textTransform: "uppercase" }}
            >
              {subtitleDeeds}
            </UI.Typography>
          </UI.Grid>
          <UI.Grid item xs={12} md={6} sx={{ mt: { xs: 3, md: 0 } }}>
            <DirectionsRunIcon sx={{ fontSize: "74px", color: "#ffffff" }} />
            <UI.Typography
              variant="h1"
              color="neutral.light"
              sx={{ textTransform: "uppercase", fontColor: "white" }}
            >
              {numberOfKm?.toLocaleString()}
            </UI.Typography>
            <UI.Typography
              variant="h1"
              color="neutral.light"
              sx={{ textTransform: "uppercase" }}
            >
              {subtitleKm}
            </UI.Typography>
          </UI.Grid>
        </UI.Grid>
      </UI.Box>
    </>
  );
};

export default MidBanner;

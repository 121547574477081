
import { useJanuaryChallengePageQuery } from "@goodgym/graphql/hooks";
import * as T from "@goodgym/graphql/types";
import { useAuth } from "@goodgym/auth";

import React from "react";

const useJanChallengePage = () => {

  const { runner } = useAuth();

  const { data } = useJanuaryChallengePageQuery({
    variables: { areaId: runner?.areas[0]?.id, runnerId: runner?.id },
  });

  const fromDate = React.useRef(new Date()).current;

  const runnerStats = {
    runnerPledgeDeeds: data?.january.runnerPledgeGoodDeeds ?? 0,
    runnerPledgeKm: data?.january.runnerPledgeDistanceKm ?? 0,
    runnerCompletedDeeds: data?.january.runnerGoodDeeds ?? 0,
    runnerCompletedKm: data?.january.runnerDistanceKm ?? 0,
  };

  const areaStats = {
    areaName: runner?.areas[0].name,
    areaPledgeDeeds: data?.january.areaPledgeGoodDeeds ?? 300,
    areaPledgeKm: data?.january.areaPledgeDistanceKm ?? 2000,
    areaCompletedDeeds: data?.january.areaGoodDeeds ?? 100,
    areaCompletedKm: data?.january.areaDistanceKm ?? 200,
  };

  const goodgymStats = {
    goodgymPledgeDeeds: data?.january.goodgymPledgeGoodDeeds ?? 1000,
    goodgymPledgeKm: data?.january.goodgymPledgeDistanceKm ?? 10000,
    goodgymCompletedDeeds: data?.january.goodgymGoodDeeds ?? 400,
    goodgymCompletedKm: data?.january.goodgymDistanceKm ?? 600,
  };

  const { data: dataSession } = T.useSessionsFeedQuery({
    variables: {
      areaIds: runner?.areas[0] ? [runner?.areas[0].id] : [],
      types: [
        "GroupRun",
        "CommunityMission",
        "Mission",
        "DocumentCheck",
        "Party",
        "TrainingSession",
        "Race",
      ],
      maxDistance: 20,
      from: fromDate,
    },
  });
  return {
  runnerStats,
  areaStats,
  goodgymStats,
  dataSession, 
  runner,
  data
}
}

export default useJanChallengePage

import * as UI from "@goodgym/components";
import React from "react";

export type AreaProgressProps = {
  runner: boolean;
  areaStats: {
    areaName?: string;
    areaPledgeDeeds: number;
    areaPledgeKm: number;
    areaCompletedDeeds: number;
    areaCompletedKm: number;
  };
  goodgymStats: {
    goodgymPledgeDeeds: number;
    goodgymPledgeKm: number;
    goodgymCompletedDeeds: number;
    goodgymCompletedKm: number;
  };
};
const AreaProgress: React.FC<AreaProgressProps> = ({
  areaStats,
  goodgymStats,
  runner,
}) => {
  const [deedsWidth, setDeedsWidth] = React.useState(0);
  const [kmWidth, setKmWidth] = React.useState(0);
  const [isVisible, setIsVisible] = React.useState(false);
  const containerRef = React.useRef(null);


  
  const percentageDeeds = () => {
    return runner
      ? Math.round(
          (areaStats.areaCompletedDeeds / areaStats.areaPledgeDeeds) * 100
        )
      : Math.round(
          (goodgymStats.goodgymCompletedDeeds /
            goodgymStats.goodgymPledgeDeeds) *
            100
        );
  };
  const percentageKm = () => {
    return runner
      ? Math.round((areaStats.areaCompletedKm / areaStats.areaPledgeKm) * 100)
      : Math.round(
        (goodgymStats.goodgymCompletedKm / goodgymStats.goodgymPledgeKm) * 100
      )
  }

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.3 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

React.useEffect(() => {
  if (isVisible) {
    const interval = setInterval(() => {
      setDeedsWidth((prevWidth) => {
        const targetWidth = Math.min(percentageDeeds(), 100); 
        if (prevWidth >= targetWidth) {
          clearInterval(interval);
          return targetWidth;
        }
        return prevWidth + 10;
      });
    }, 100);

    const intervalKm = setInterval(() => {
      setKmWidth((prevWidth) => {
        const targetWidth = Math.min(percentageKm(), 100); 
        if (prevWidth >= targetWidth) {
          clearInterval(intervalKm);
          return targetWidth;
        }
        return prevWidth + 10;
      });
    }, 100);

    return () => {
      clearInterval(interval);
      clearInterval(intervalKm);
    };
  }
}, [isVisible]);
  return (
    <UI.Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
      }}
    >
      <UI.Card
        sx={{
          width: { xs: "90%", sm: "55%", md: "85%" },
          borderRadius: "10px",
        }}
      >
        <UI.Box
          sx={{
            width: "110%",
            borderTop: "10px solid #E11018",
            ml: -2,
          }}
        />
        <UI.CardContent sx={{ mt: 0 }}>
          <UI.Typography
            variant="h2"
            sx={{
              textAlign: "center",
            }}
          >
            Progress in {runner ? areaStats.areaName : "Goodgym"}
          </UI.Typography>

          <UI.Box
            ref={containerRef}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <UI.Typography variant="h1" sx={{ mt: 3, textAlign: "center" }}>
              {runner
                ? areaStats.areaCompletedDeeds
                : goodgymStats.goodgymCompletedDeeds}{" "}
              Good Deeds Completed
            </UI.Typography>
            <UI.Container sx={{ textAlign: "center", my: 5 }}>
              <UI.Box
                sx={{
                  width: "100%",
                  backgroundColor: "#000000",

                  borderRadius: "30px",
                }}
              >
                <UI.Box
                  sx={{
                    width: `${deedsWidth}%`,
                    height: "80px",
                    backgroundColor: "#F01E28",
                    borderTopLeftRadius: "20px",
                    borderBottomLeftRadius: "20px",
                    transition: "width 1s ease-in-out",
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                />
              </UI.Box>
              <UI.Typography variant="h5" sx={{ textAlign: "center", my: 3 }}>
                {runner ? "GoodGym " + areaStats.areaName + " " : "Goodgym "}
                has completed{" "}
                <span style={{ color: "#E11018" }}>
                  {percentageDeeds()}%
                </span>{" "}
                of its good deeds with{" "}
                <span style={{ color: "#E11018" }}>
                  {runner
                    ? areaStats.areaCompletedDeeds
                    : goodgymStats.goodgymCompletedDeeds}
                </span>{" "}
                out of{" "}
                <span style={{ color: "#E11018" }}>
                  {runner
                    ? areaStats.areaPledgeDeeds
                    : goodgymStats.goodgymPledgeDeeds}{" "}
                </span>
                good deeds done.
              </UI.Typography>
            </UI.Container>
            <UI.Typography variant="h1" sx={{ mt: 3, textAlign: "center" }}>
              {runner
                ? areaStats.areaCompletedKm
                : goodgymStats.goodgymCompletedKm}{" "}
              Km completed
            </UI.Typography>
            <UI.Container sx={{ textAlign: "center", my: 5 }}>
              <UI.Box
                sx={{
                  width: "100%",
                  backgroundColor: "#000000",
                  borderRadius: "30px",
                }}
              >
                <UI.Box
                  sx={{
                    width: `${kmWidth}%`,
                    height: "80px",
                    backgroundColor: "#F01E28",
                    borderTopLeftRadius: "20px",
                    borderBottomLeftRadius: "20px",
                    transition: "width 1s ease-in-out",
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                />
              </UI.Box>
              <UI.Typography variant="h5" sx={{ textAlign: "center", my: 3 }}>
                {runner ? "GoodGym " + areaStats.areaName + " " : "Goodgym "}
                has also run{" "}
                <span style={{ color: "#E11018" }}>
                  {runner
                    ? areaStats.areaCompletedKm
                    : goodgymStats.goodgymCompletedKm}
                  Km
                </span>{" "}
                out of its pledged
                <span style={{ color: "#E11018" }}>
                  {" "}
                  {runner
                    ? areaStats.areaPledgeKm
                    : goodgymStats.goodgymPledgeKm}
                  Km
                </span>{" "}
                which means they are{" "}
                <span style={{ color: "#E11018" }}>{percentageKm()}%</span>{" "}
                towards their target.
              </UI.Typography>
            </UI.Container>
            <UI.Button
              sx={{ fontSize: "1.5rem" }}
              href={"https://staging.goodgym.org/january_challenge_table"}
            >
              View area league board
            </UI.Button>
          </UI.Box>
        </UI.CardContent>
      </UI.Card>
    </UI.Box>
  );
};

export default AreaProgress;
